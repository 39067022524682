import React from 'react'
import { graphql, Link } from 'gatsby'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import GeneralLayout from '../components/layouts/general'
import AppLayout from '../components/layouts/appLayout'
import { HeadingContainer } from '../components/common/typography'
import SEO from '../components/seo'
import {
  BLACK_COLOR_RGB,
  PRIMARY_COLOR,
  PRIMARY_COLOR_RGB,
} from '../components/common/color'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'

deckDeckGoHighlightElement()

const Content = styled.div`
  padding: 1rem 0 3rem;
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
`

const PostDate = styled.span`
  color: ${BLACK_COLOR_RGB(0.7)};
`

const BackToBlogLink = styled(Link)`
  position: relative;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    width: 56px;
    height: 4px;
    background-color: ${PRIMARY_COLOR_RGB(0.8)};
  }

  &:hover {
    &::before {
      background-color: ${BLACK_COLOR_RGB(0.6)};
    }
  }
`

const BlogView = ({ data }) => {
  const {
    frontmatter: { title, date },
    html,
    parent: { name },
    fields: { sourceInstanceName },
  } = data.blog
  return (
    <AppLayout>
      <SEO title={title} canonical={`${sourceInstanceName}/${name}`} />
      <Global
        styles={css`
          .blog-html {
            font-family: var(--primary-font);
            margin: 1rem 0;

            h1 {
              margin: 0.5rem 0;
            }

            h4 {
              margin: 0.25rem 0;
            }

            * {
              line-height: 1.5;
            }

            img,
            deckgo-highlight-code {
              width: 100%;
              display: block;
              margin: 0 auto;
              max-width: 800px;
            }
          }
        `}
      />
      <Content>
        <BackToBlogLink to="/blog">Back to Blog</BackToBlogLink>
        <HeadingContainer title={title} style={{ marginTop: '2rem' }} />
        <PostDate>
          <strong>Published At: </strong>
          {date}
        </PostDate>
        <div
          className="blog-html"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </Content>
    </AppLayout>
  )
}

export const query = graphql`
  query($blogPostId: String!) {
    blog: markdownRemark(
      id: { eq: $blogPostId }
      fields: { sourceInstanceName: { eq: "blog" } }
    ) {
      fields {
        sourceInstanceName
      }
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        description
        topic
        author
      }
      html
      id
      parent {
        ... on File {
          id
          name
        }
      }
    }
  }
`

export default BlogView
